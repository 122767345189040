module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='  <div class="headerBar">\n    <div class="search-field">\n      <input type="text" value="'+
((__t=(searchString))==null?'':__t)+
'" id="graphManagementSearchInput" class="search-input" placeholder="Search..."/>\n      <i id="graphManagementSearchSubmit" class="fa fa-search"></i>\n      <!-- <img id="graphManagementSearchSubmit" class="search-submit-icon"> -->\n    </div>\n     <div class="headerButtonBar">\n        <ul class="headerButtonList">\n          <li class="enabled">\n            <a id="graphManagementToggle" class="headerButton">\n              <span class="icon_arangodb_settings2" title="Settings"></span>\n            </a>\n          </li>\n        </ul>\n      </div>\n  </div>\n\n\n  <div id="graphManagementDropdown2" class="headerDropdown">\n\n    <div id="graphManagementDropdown" class="dropdownInner">\n      <ul>\n        <li class="nav-header">Sorting</li>\n        <li><a>\n          <label class="checkbox checkboxLabel">\n            <input class="css-checkbox" type="checkbox" id="graphSortDesc">\n            <i class="fa"></i>\n            Sort descending\n          </label>\n        </a></li>\n      </ul>\n    </div>\n\n  </div>\n\n\n  <div class="contentDiv" id="arangoCollectionsContainer">\n    <div id="graphManagementThumbnailsIn" class="tileList pure-u">\n      <div class="tile pure-u-1-1 pure-u-sm-1-2 pure-u-md-1-3 pure-u-lg-1-4 pure-u-xl-1-6">\n        <div class="fullBorderBox">\n          <a href="#" id="createGraph" class="add">\n            <span id="newGraph" class="pull-left add-Icon"><i class="fa fa-plus-circle"></i></span>\n            Add Graph\n          </a>\n        </div>\n      </div>\n\n\n      ';
 graphs.forEach(function(graph) {
        var graphName = graph.get("_key");
        var isSmart = graph.get("isSmart");
      
__p+='\n\n      <div class="tile tile-graph pure-u-1-1 pure-u-sm-1-2 pure-u-md-1-3 pure-u-lg-1-4 pure-u-xl-1-6" id="'+
((__t=(graphName ))==null?'':__t)+
'_tile">\n        <div class="paddingBox">\n          <div class="borderBox"></div>\n          <div class="iconSet">\n            <span class="icon_arangodb_settings2 editGraph" id="'+
((__t=(graphName ))==null?'':__t)+
'_settings" alt="Edit graph" title="Edit graph"></span>\n          </div>\n          <span class="icon_arangodb_edge5 tile-icon"></span>\n          <span class="icon_arangodb_edge5 icon_arangodb_edge5-2 tile-icon"></span>\n          <div class="tileBadge">\n            ';
 if (isSmart === true) { 
__p+='\n              <span><div class="corneredBadge inProgress">Smart</div></span>\n            ';
 } 
__p+='\n          </div>\n          <h5 class="collectionName">'+
((__t=(graphName ))==null?'':__t)+
'</h5>\n        </div>\n      </div>\n\n      ';
});
__p+='\n     </div>\n  </div>\n\n';
}
return __p;
};
